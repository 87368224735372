<template>
  <div class="news_list_page" v-infinite-scroll="moreNews" style="overflow:auto" :infinite-scroll-distance="80" :infinite-scroll-delay="300" :infinite-scroll-disabled="!isLoading">
    <div class="box">
      <div class="news-item-box">
        <div class="page_name">行业新闻>></div>
        <ul class="infinite-list items">
          <li v-show="item.title!==''" v-for="item in newsList" :key="item.id">
            <el-image class="news-img" :src="item.cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline news-img"></i>
              </div>
            </el-image>
            <div class="text">
              <h3 @click="goNewsDetailsPage(item.id)" class="hand">{{item.title}}</h3>
              <h4>{{item.subtitle}}</h4>
              <div>
                <!-- <span class="icon">{{item.source}}</span> -->
                <span>{{item.viewcount}}次阅读</span>
                <span>{{formatTime(item.publishDate)}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <p v-if="isLoading">加载中...</p>
      <p v-else>没有更多了</p>
    </div>
  </div>
</template>
<script>
import { getNewsList } from '@/api/infoRequest.js'
export default {
  data () {
    return {
      newsList: [], // 资讯列表数据
      // 分页数据
      pageObj: {
        pageNum: 1,
        pageSize: 10
      },
      isLoading: true,
    }
  },
  methods: {
    // 处理时间
    formatTime (timestamp) {
      const date = new Date(timestamp)
      const Y = date.getFullYear()
      const M = this.zeroFill(date.getMonth() + 1)
      const D = this.zeroFill(date.getDate())
      return Y + '-' + M + '-' + D
    },
    // 补零函数
    zeroFill (time) {
      return time >= 10 ? time : '0' + time
    },
    // 跳转到资讯详情页面
    goNewsDetailsPage (newsId) {
      this.$router.push({ path: '/newsDetails', query: { newsId } })
    },
    //  加载更多
    moreNews () {
      this.pageObj.pageNum++
      this.getLatestNews()
    },
    // 获取资讯列表
    async getLatestNews () {
      const res = await getNewsList({ ...this.pageObj, category: 2 })
      if (res.data.length === 0) {
        return this.isLoading = false
      }
      this.newsList = [...this.newsList, ...res.data]
    }
  },
  created () {
    this.getLatestNews()
  }
}
</script>
<style lang="less" scoped>
.news_list_page {
  width: 100%;
  height: calc(100vh - 191px);
  // height: calc(100vh - 76px);
  background-color: #f5f7fb;
  &::-webkit-scrollbar {
    /* 滚动条容器 */
    width: 6px;
    background-color: transparent;
    // display: none;
  }
  &::-webkit-scrollbar-thumb {
    /* 滚动条手柄 */
    /* background-color: #00adb5; */
    background-color: #c1c1c1;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    /*滚动条的轨道*/
    border-radius: 3px;
  }
  .page_name {
    width: 1200px;
    margin: 0 auto;
  }
  .box {
    padding: 10px 0;
    background-color: #f5f7fb;
    .items {
      width: var(--width);
      padding: 0 100px;
      margin: 0 auto;
      li {
        display: flex;
        align-items: center;
        box-shadow: 0 0 2px 0 #ccc;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 40px;
        margin-bottom: 15px;
        &:hover {
          box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.3);
          transition: all 0.3s;
        }
        .news-img {
          display: inline-block;
          width: 160px;
          height: 120px;
          margin-right: 40px;
          color: #999;
        }
        .el-icon-picture-outline {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 120px;
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex: 1;
          height: 120px;
          h3 {
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &:hover {
              text-decoration: underline;
            }
          }
          h4 {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          span {
            margin-right: 30px;
            font-size: 14px;
            color: #8b8b8b;
          }
        }
      }
    }
    p {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
  }
}
</style>